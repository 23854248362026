import { useMutation } from 'react-query';

import { getQuotes } from '@src/api-requests/getQuotes/getQuotes';
import { GetQuotesOptions } from '@src/api-requests/getQuotes/types';

type ValidatePromoCodeMutationResponse = {
  promoCode: string | undefined;
  isValid: boolean;
};

export type ValidatePromoCodeMutationOptions = GetQuotesOptions;

const validatePromoCodeMutation = async (
  options: ValidatePromoCodeMutationOptions
): Promise<ValidatePromoCodeMutationResponse> => {
  const { result } = await getQuotes(options);
  const allQuotes = result.flatMap((quotesWrapper) => quotesWrapper.quotes);
  const quote = allQuotes.find(
    ({ offer }) => offer?.promocode && offer.promocode === options.promoCode
  );
  const promoCode = quote?.offer?.promocode;

  return {
    promoCode: promoCode,
    isValid: !!promoCode,
  };
};

export const useValidatePromoCodeMutation = () =>
  useMutation(['validatePromoCodeMutation'], validatePromoCodeMutation);
