import { IQuote } from '@src/types/Quote';

export const getExcessValuesFromQuotes = (quotes: IQuote[] | undefined) => {
  if (!quotes) {
    return [];
  }

  const uniqueExcessValues = [...new Set(quotes.map((quote) => quote.excess))];

  return uniqueExcessValues;
};
