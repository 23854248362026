import { Margin, Small, Strong, TextLink } from '@ovotech/element';
import styled from 'styled-components';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { excessDetails } from '../excessDetails';

import { Toggle } from '@components';
import { SupRef } from '@components/Footer';
import { defaultFootnotes } from '@constants/footnotes';
import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { useModalContext } from '@contexts/ModalContext';
import { excessAtom, journeyAtom } from '@src/store/store';
import { getExcessValuesFromQuotes } from '@utils/getExcessValuesFromQuotes';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { Journey } from '@src/types/Journey';

const ExcessContainer = styled.div`
  text-align: left;
`;

const ExcessText = styled.span`
  line-height: ${({ theme }) => theme.core.space[10]};
`;

export const WhatIsExcessLink = styled(TextLink)`
  cursor: pointer;
`;

const defaultExcessOption = {
  label: '£0',
  value: '0',
  excess: 0,
} as const satisfies ExcessOption;

type ExcessOption = {
  label: string;
  value: string;
  excess: number;
};

type NewExcessToggleProps = {
  displayExcessText?: boolean;
};

export const NewExcessToggle = ({ displayExcessText }: NewExcessToggleProps) => {
  const mixpanel = useCustomMixpanel();
  const { showModal } = useModalContext();
  const journey = useAtomValue(journeyAtom);
  const [excess, setExcess] = useAtom(excessAtom);
  const { allQuotes } = useQuotesPlatform();

  const excessOptions = useMemo((): ExcessOption[] => {
    const uniqueExcessValues = getExcessValuesFromQuotes(allQuotes);
    return uniqueExcessValues.map((excess) => ({
      label: `£${excess}`,
      value: `${excess}`,
      excess,
    }));
  }, [allQuotes]);

  const selectedExcessOption = excessOptions.find((option) => option.excess === excess);

  const toggleValue = selectedExcessOption?.value || defaultExcessOption.value;
  const toggleOptions = excessOptions.length ? excessOptions : [defaultExcessOption];

  const handleSelectExcessValue = (value: string): void => {
    const option = excessOptions.find((option) => option.value === value);
    const excess = option?.excess || 0;
    mixpanel.track(MixpanelEvents.TOGGLE_EXCESS, { selected: excess });
    setExcess(excess);
  };

  return (
    <ExcessContainer>
      <ExcessText>
        <Strong>
          Choose your excess
          {!selectedExcessOption?.excess && (
            <SupRef
              footnote={{
                ...defaultFootnotes.completePolicyExcess,
                id: 'complete-policy-excess',
              }}
            />
          )}
        </Strong>{' '}
        <Small>(per claim)</Small>
      </ExcessText>

      <Toggle
        data-testid="excess-toggle"
        id="excess"
        value={toggleValue}
        options={toggleOptions}
        onSelect={handleSelectExcessValue}
        isHomeRecover={journey === Journey.HomeRecover}
      />
      <Margin top={3} bottom={2}>
        {displayExcessText && (
          <WhatIsExcessLink data-testid="excess-info-icon" onClick={() => showModal(excessDetails)}>
            What is an excess?
          </WhatIsExcessLink>
        )}
      </Margin>
    </ExcessContainer>
  );
};
