import { defaults } from 'lodash';

import { GetQuotesOptions, GetQuotesRequestBody } from './types';
import { defaultGetQuotesOptions } from './constants';

import {
  ApplianceType,
  EligibleReward,
  PropertyOwner,
  PropertyType,
  Require,
} from '@entities/enums';
import { getBoilerAgeNumber } from '@utils/getBoilerAgeNumber';

export const createGetQuotesRequestBody = (options: GetQuotesOptions): GetQuotesRequestBody => {
  const mergedOptions = defaults({}, options, defaultGetQuotesOptions);

  const {
    isAbs,
    isCreditEligible,
    isGuestHouse,
    isLandlord,
    isHomeRecover,
    boilerMake,
    boilerModel,
    boilerAge,
    promoCode,
    applianceId,
  } = mergedOptions;

  const requestBody = {
    appliances: [
      {
        id: applianceId,
        type: ApplianceType.GASBOILER,
        manufacturer: boilerMake,
        model: boilerModel,
        age: getBoilerAgeNumber(boilerAge),
        isUnder45kw: true,
        naturalMainsGas: true,
        singleBoiler: true,
        needsRepair: isHomeRecover,
      },
    ],
    property: {
      type: isGuestHouse ? PropertyType.GUESTHOUSE : PropertyType.DOMESTIC,
      owner: isLandlord ? PropertyOwner.LANDLORD : PropertyOwner.HOMEOWNER,
    },
    requires: isAbs ? [Require.ABS] : [Require.INSURANCE],
    eligibleRewardTypes: isCreditEligible ? [EligibleReward.ENERGY_CREDIT] : [],
    promoCode,
  } satisfies GetQuotesRequestBody;

  return requestBody;
};
