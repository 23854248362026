import { defaults } from 'lodash';

import { GetQuotesOptions, GetQuotesRequestHeaders } from './types';
import { defaultGetQuotesOptions } from './constants';

import { getSessionDate } from '@utils/DateUtils';

export const createGetQuotesRequestHeaders = (
  options: GetQuotesOptions
): GetQuotesRequestHeaders => {
  const mergedOptions = defaults({}, options, defaultGetQuotesOptions);
  const { is95Excess, isAgent } = mergedOptions;
  const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

  const date = getSessionDate();

  const headers = {
    ...(is95Excess && { 'x-audience-source': 'BEYOND' }),
    ...(isAgent && { 'x-audience-type': 'AGENT' }),
    ...(date && !isProduction && { 'x-date-now': date }),
  };

  return headers;
};
