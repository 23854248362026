import { CTAButton, Icon, showToast, TextField } from '@ovotech/element';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import mixpanel from 'mixpanel-browser';

import { useValidatePromoCodeMutation } from '@hooks/mutations/useValidatePromoCodeMutation';
import { BoilerBrand, ErrorMessages, MixpanelEvents } from '@entities/enums';
import PromoService from '@services/PromoService';
import { homePlanStoreAtom, promoCodeAtom, Store, store } from '@src/store/store';
import { clearPromoCodeUrlParams } from '@utils/clearPromoCodeUrlParams';
import { Journey } from '@src/types/Journey';

const PromoboxContainer = styled.div`
  padding: 0;
`;

const FlexGrid = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 65% 30%;
  align-items: end;
  gap ${(props) => props.theme.core.space[3]};

  span {
      font-size: 16px;
  }
`;

const InlineCross = styled(Icon)`
  position: absolute;
  right: 40%;
  top: 58%;
  cursor: pointer;
`;

const StyledTextFieldWrapper = styled.span`
  input {
    padding-right: 40px;
  }
`;

export const PromoCodeBoxV2 = () => {
  const [promoCode, setPromoCode] = useAtom(promoCodeAtom);
  const [promoInput, setPromoInput] = useState<string | undefined>(promoCode);
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: validatePromoCode } = useValidatePromoCodeMutation();

  const applyPromoCode = useCallback(
    async (value: string | undefined | null, store: Store) => {
      const { promoCode, journey, isAgent, isCreditEligible, boilerDetails } = store;

      if (!value) {
        setError(ErrorMessages.PROMO_CODE_NOT_APPLIED);
        return;
      }

      if (value === promoCode) {
        setError(ErrorMessages.PROMO_CODE_ALREADY_APPLIED);
        showToast({
          duration: 3000,
          variant: 'error',
          message: 'Promo code already applied',
        });
        return;
      }

      try {
        const response = await validatePromoCode({
          promoCode: value,
          isAbs: journey === Journey.Abs,
          isAgent,
          applianceId: boilerDetails.applianceId,
          boilerAge: boilerDetails.boilerAge,
          boilerMake: boilerDetails.make === '' ? BoilerBrand.DEFAULT : boilerDetails.make,
          is95Excess: journey === Journey.InsuranceHighExcess,
          isCreditEligible,
          isHomeRecover: journey === Journey.HomeRecover,
        });

        if (!response.isValid) {
          setError(ErrorMessages.PROMO_CODE_INVALID);
          showToast({
            duration: 3000,
            variant: 'error',
            message: 'Promo code is not valid',
          });
          return;
        }

        mixpanel.track(MixpanelEvents.PROMOCODE_APPLIED, { promoCode: response.promoCode });
        setError(null);
        setPromoCode(response.promoCode);
        showToast({
          duration: 3000,
          variant: 'default',
          message: 'Promo code applied',
        });
      } catch {
        setError('Error');
        showToast({
          duration: 3000,
          variant: 'error',
          message: 'Error',
        });
      }
    },
    [setPromoCode, validatePromoCode]
  );

  const handleRemovePromoCode = () => {
    PromoService.clear();
    setPromoCode(undefined);
    setPromoInput('');
    showToast({
      duration: 3000,
      variant: 'default',
      message: 'Promo code removed',
    });
  };

  useEffect(() => {
    setPromoInput(promoCode);
  }, [promoCode]);

  useEffect(() => {
    const promoCodeFromUrl = new URLSearchParams(document.location.search).get('promoCode');
    if (promoCodeFromUrl === '') {
      applyPromoCode(promoCodeFromUrl, store.get(homePlanStoreAtom));
    }
    clearPromoCodeUrlParams();
  }, [validatePromoCode, applyPromoCode]);

  return (
    <PromoboxContainer>
      <FlexGrid>
        <StyledTextFieldWrapper>
          <TextField
            id="promo-code-box"
            data-testid="promo-code-box"
            label="Enter a promo code"
            fullWidth
            value={promoInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPromoInput(e.target.value.toUpperCase())
            }
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                applyPromoCode(promoInput, store.get(homePlanStoreAtom));
              }
            }}
            error={error}
          />
        </StyledTextFieldWrapper>
        <CTAButton
          variant={'secondary'}
          onClick={() => applyPromoCode(promoInput, store.get(homePlanStoreAtom))}
          id="apply-promo-code"
          data-testid="apply-promo-code"
          fullWidth={false}
          type={'button'}
        >
          Apply
        </CTAButton>
        {promoCode && promoCode === promoInput && (
          <InlineCross
            name="cross"
            size={18}
            onClick={handleRemovePromoCode}
            id="remove-promo-code"
            data-testid="remove-promo-code"
          />
        )}
      </FlexGrid>
    </PromoboxContainer>
  );
};
