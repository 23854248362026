import { DefaultGetQuotesOptions } from './types';

import { BoilerBrand, BoilerModel } from '@entities/enums';

export const defaultGetQuotesOptions = {
  isAbs: false,
  isCreditEligible: false,
  isGuestHouse: false,
  isLandlord: false,
  isHomeRecover: false,
  boilerMake: BoilerBrand.DEFAULT,
  boilerModel: BoilerModel.VALID,
  boilerAge: 0,
  promoCode: '',
} as const satisfies DefaultGetQuotesOptions;
