import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import { Card, Margin, Stack } from '@ovotech/element';
import styled from 'styled-components';
import { useDecision } from '@optimizely/react-sdk';
import { omitBy } from 'lodash';
import { useAtomValue } from 'jotai';

import { PersonalDetailsInputs, PersonalDetailsInputsType } from './PersonalDetailsInputs';
import { EvChargerEligibility } from './EvChargerEligibility';
import { EPDEligibility } from './EPDEligibility';

import { BasicPage } from '@components';
import { TrackFormErrors } from '@components/TrackFormErrors';
import { ErrorMessages, MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { useMediaIs } from '@hooks/useMediaIs';
import { mqMediumUp } from '@utils/mediaQuery';
import { MarginDivider } from '@src/Styles/Common.Styles';
import { LoginCard } from '@components/LoginCard';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { createCustomerDetails } from '@src/factories/Signup/SignupFactory';
import { formatPersonalDetailsErrors } from '@utils/signupValidationHelper';
import { useSignupValidation } from '@hooks/useSignupValidation';
import { Validate as ValidatePersonalDetails } from '@src/validation/personalDetails/schema';
import { journeyAtom, selectedProductAtom } from '@src/store/store';
import useEvCharger from '@hooks/useEvCharger';
import useEpdCover from '@hooks/useEpdCover';
import { ScrollToFieldError } from '@components/ScrollToFieldError';
import { Journey } from '@src/types/Journey';
import { BasketV2 } from '@components/BasketV2/BasketV2';
import { BasketNavigationButtonsV2 } from '@components/BasketV2/BasketNavigationsButtons';
import { ProductCode } from '@src/types/Quote';

const StyledCard = styled(Card)`
  ${({ theme: { core } }) =>
    `padding: ${core.space[4]}; 
    ${mqMediumUp(`padding: ${core.space[6]};`)};
   `}
`;

const fieldsInOrder: (keyof PersonalDetailsInputsType)[] = [
  'homeOwnership',
  'notGuesthouse',
  'evChargerOutput',
  'evChargerOwnership',
  'evChargerUseGenerateIncome',
  'title',
  'forename',
  'surname',
  'email',
  'telephone',
  'postcode',
  'selectedAddressFromFinder',
  'addressLine1',
  'addressLine2',
  'town',
];

interface PersonalDetailsFormProps {
  initialValues: PersonalDetailsInputsType;
  isLoggedIn: boolean;
  isHomeRecover: boolean;
  onClickBack(): void;
  onSubmit(values: PersonalDetailsInputsType): void;
}

export const NewPersonalDetailsForm = ({
  onClickBack,
  onSubmit,
  initialValues,
  isHomeRecover,
}: PersonalDetailsFormProps) => {
  // If we already have an address saved for the user, we prefer to show the manual edit by default
  const addressFound = !!initialValues?.addressLine1;
  const [manualAddressEdit, setManualAddressEdit] = useState(addressFound);
  const { track } = useCustomMixpanel();

  const [{ enabled: useNewSignupAPI }] = useDecision(OptimizelyFeatureFlag.NEW_SIGNUP_API);
  const isMobile = useMediaIs('mobile', 'max');
  const journey = useAtomValue(journeyAtom);
  const selectedProduct = useAtomValue(selectedProductAtom);
  const { validateCustomerDetails } = useSignupValidation();
  const { evChargerSelected } = useEvCharger();
  const { epdCoverSelected } = useEpdCover();

  const validate = useCallback(
    (values: PersonalDetailsInputsType): FormikErrors<PersonalDetailsInputsType> => {
      let errors: FormikErrors<PersonalDetailsInputsType> = {};
      if (useNewSignupAPI) {
        const cleanValues = omitBy(values, (value) => typeof value === 'string' && value === '');
        const personalDetails = createCustomerDetails(cleanValues);
        const result = validateCustomerDetails(personalDetails);
        errors = result.errors ? formatPersonalDetailsErrors(result.errors) : {};
      }

      if (!useNewSignupAPI) {
        errors = ValidatePersonalDetails(values);
      }

      if (!manualAddressEdit && !values.selectedAddressFromFinder) {
        errors.selectedAddressFromFinder = ErrorMessages.SELECT_ADDRESS_REQUIRED;
      }

      if (evChargerSelected && !values.evChargerOwnership) {
        track(MixpanelEvents.VALIDATION_ERROR, {
          errorId: 'evChargerOwnershipValidationError',
        });
        errors.evChargerOwnership = 'Please confirm you own the EV charger.';
      }

      if (evChargerSelected && !values.evChargerUseGenerateIncome) {
        track(MixpanelEvents.VALIDATION_ERROR, {
          errorId: 'evChargerUseGenerateIncomeValidationError',
        });
        errors.evChargerUseGenerateIncome = `Please confirm you don't need the EV charger to generate income.`;
      }

      if (evChargerSelected && !values.evChargerOutput) {
        track(MixpanelEvents.VALIDATION_ERROR, {
          errorId: 'evChargerOutputValidationError',
        });
        errors.evChargerOutput = 'Please confirm your charger does not have an output over 22kW.';
      }

      if (epdCoverSelected && !values.homeOwnership) {
        track(MixpanelEvents.VALIDATION_ERROR, {
          errorId: 'homeOwnershipValidationError',
        });
        errors.homeOwnership = 'Confirm you’re the homeowner and live in the property.';
      }

      if (epdCoverSelected && !values.notGuesthouse) {
        track(MixpanelEvents.VALIDATION_ERROR, {
          errorId: 'notGuesthouseValidationError',
        });
        errors.notGuesthouse = `Confirm your home isn’t used as a B&B or a guest house. `;
      }

      return errors;
    },
    [
      manualAddressEdit,
      validateCustomerDetails,
      useNewSignupAPI,
      evChargerSelected,
      track,
      epdCoverSelected,
    ]
  );

  const isAbs = selectedProduct === ProductCode.ABS;

  const backButtonText = isHomeRecover ? 'Back to Repair details' : 'Back to Plans';

  const nextText =
    evChargerSelected || epdCoverSelected ? 'Go to Payment details' : 'Go to Boiler details';

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {(formik: FormikProps<PersonalDetailsInputsType>) => {
        return (
          <Form data-testid="personal-details-form" role="form">
            <TrackFormErrors errors={formik.errors} trackErrors={formik.submitCount > 0} />
            <BasicPage.Content>
              <BasicPage.Left>
                <Stack spaceBetween={3}>
                  {!isHomeRecover && <LoginCard />}
                  <ScrollToFieldError<PersonalDetailsInputsType> fieldsInOrder={fieldsInOrder} />
                  <EvChargerEligibility formik={formik} />
                  <EPDEligibility formik={formik} />
                  <StyledCard>
                    <PersonalDetailsInputs
                      {...formik}
                      manualAddressEdit={manualAddressEdit}
                      setManualAddressEdit={(manualAddressEdit) => {
                        track(MixpanelEvents.BUTTON_CLICK, {
                          page: 'PersonalDetails',
                          buttonId: 'manualAddressToggle',
                          selected: manualAddressEdit,
                        });
                        setManualAddressEdit(manualAddressEdit);
                      }}
                      handleClickManualAddress={() => setManualAddressEdit(!manualAddressEdit)}
                      manualAddressLabel={
                        manualAddressEdit ? 'Find address from postcode' : 'Enter address manually'
                      }
                    />
                    {!isMobile && (
                      <>
                        <MarginDivider type={'differentiated'} />
                        <BasketNavigationButtonsV2
                          isLoading={formik.isSubmitting}
                          nextText={nextText}
                          productCode={selectedProduct}
                          backButtonConfig={{
                            text: backButtonText,
                            onClick: onClickBack,
                          }}
                        />
                      </>
                    )}
                  </StyledCard>
                </Stack>
              </BasicPage.Left>
              <BasicPage.Right>
                <>
                  <BasketV2
                    showExcessToggle={!isAbs && journey !== Journey.InsuranceHighExcess}
                    nextText={nextText}
                  />

                  {isMobile && (
                    <>
                      <Margin top={4} />
                      <BasketNavigationButtonsV2
                        isLoading={formik.isSubmitting}
                        nextText={nextText}
                        productCode={selectedProduct}
                        backButtonConfig={{
                          text: backButtonText,
                          onClick: onClickBack,
                        }}
                      />
                    </>
                  )}
                </>
              </BasicPage.Right>
            </BasicPage.Content>
          </Form>
        );
      }}
    </Formik>
  );
};
