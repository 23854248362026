import { TermsAndConditionsLink } from '@components/links/link.styled';
import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { DocumentType, IQuotesWrapper } from '@src/types/Quote';

interface Props {
  quoteWrapper: IQuotesWrapper;
  mixpanelEventButtonId?: string;
}

export const NewTermsLink = ({ quoteWrapper, mixpanelEventButtonId = 'terms' }: Props) => {
  const { track } = useCustomMixpanel();

  const { product, documents } = quoteWrapper;

  const terms = documents.find((doc) => doc.type === DocumentType.TERMS);

  return (
    <TermsAndConditionsLink
      data-testid={`terms-and-conditions`}
      href={terms?.url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Terms and conditions"
      onClick={() =>
        track(MixpanelEvents.BUTTON_CLICK, {
          buttonId: mixpanelEventButtonId,
          coverType: product.code,
        })
      }
    >
      Terms and conditions
    </TermsAndConditionsLink>
  );
};
