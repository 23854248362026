import { PageProps } from 'gatsby';
import { useDecision } from '@optimizely/react-sdk';

import { PersonalDetailsPage } from '@src/modules/PersonalDetails/PersonalDetailsPage';
import { useCustomMixpanel } from '@services/Mixpanel';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { NewPersonalDetailsPage } from '@src/modules/PersonalDetails/NewPersonalDetailsPage';

const Details = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  if (isQuotesPlatform) {
    return <NewPersonalDetailsPage mixpanel={mixpanel} location={props.location} />;
  }

  return <PersonalDetailsPage mixpanel={mixpanel} {...props} />;
};

export default Details;
