import { navigate } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { useDecision, withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { WindowLocation } from '@reach/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Mixpanel } from 'mixpanel-browser';

import { PersonalDetailsInputsType } from './PersonalDetailsInputs';
import { NewPersonalDetails } from './NewPersonalDetails';

import * as Logger from '@src/logging/logger';
import { LOGGED_IN_REPAIR_COST } from '@constants/homeRepair';
import {
  ROUTE_BOILER,
  ROUTE_EPD_LANDING,
  ROUTE_EPD_PAYMENT,
  ROUTE_EV_LANDING,
  ROUTE_EV_PAYMENT,
  ROUTE_HOME,
  ROUTE_HOME_RECOVER,
  ROUTE_HOME_RECOVER_BOILER,
  ROUTE_HOME_RECOVER_REPAIR_DETAILS,
} from '@constants/routes';
import { MixpanelEvents } from '@entities/enums';
import AccountService, { emptyAccount } from '@services/AccountService';
import { getReferrerUrlFor } from '@utils/getReferrerUrl';
import { getOutcode } from '@utils/getOutcode';
import { useAuth } from '@hooks/useAuth';
import { useIsCreditEligible } from '@hooks/useIsCreditEligible';
import { useAppContext } from '@contexts/AppContext';
import { DataLayer } from '@services/DataLayerService';
import {
  journeyAtom,
  isAgentAtom,
  personalDetailsAtom,
  repairAtom,
  trackingAtom,
  evChargerEligibilityAtom,
  epdEligibilityAtom,
  selectedProductAtom,
} from '@src/store/store';
import useEvCharger from '@hooks/useEvCharger';
import useEpdCover from '@hooks/useEpdCover';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { Journey } from '@src/types/Journey';

interface Props extends WithOptimizelyProps {
  mixpanel: Mixpanel;
  location: WindowLocation;
}

const NewPersonalDetailsPageComponent = ({ location, optimizely, mixpanel }: Props) => {
  const [initialValues, setInitialValues] = useState<PersonalDetailsInputsType>();
  const { user, loading } = useAuth();
  const isCreditEligible = useIsCreditEligible();

  const [{ enabled: kapiAccountDetailsEnabled }] = useDecision(
    OptimizelyFeatureFlag.KAPI_MIGRATION,
    {
      autoUpdate: true,
    }
  );
  const mixpanelRef = useRef(mixpanel);
  const optimizelyRef = useRef(optimizely);

  const [repair, setRepair] = useAtom(repairAtom);
  const journey = useAtomValue(journeyAtom);
  const isAgent = useAtomValue(isAgentAtom);
  const tracking = useAtomValue(trackingAtom);
  const setPersonalDetails = useSetAtom(personalDetailsAtom);
  const setEVCharger = useSetAtom(evChargerEligibilityAtom);
  const [epdEligibility, setEPDEligibility] = useAtom(epdEligibilityAtom);
  const evCharger = useAtomValue(evChargerEligibilityAtom);
  const selectedProduct = useAtomValue(selectedProductAtom);

  const { setLoading } = useAppContext();

  const { evChargerSelected } = useEvCharger();
  const { epdCoverSelected } = useEpdCover();

  useEffect(() => {
    if (!selectedProduct) {
      navigate(journey !== Journey.HomeRecover ? ROUTE_HOME : ROUTE_HOME_RECOVER, {
        replace: true,
      });
    } else {
      if (!user?.accountId && loading) {
        return;
      }
      AccountService.get(user?.accountId, {
        useKapi: kapiAccountDetailsEnabled,
      })
        .then((account) => {
          setInitialValues({ ...account, ...evCharger, ...epdEligibility });
        })
        .then(() => {
          if (
            journey === Journey.HomeRecover &&
            repair &&
            user?.accountId &&
            repair.cost !== LOGGED_IN_REPAIR_COST
          ) {
            setRepair({
              ...repair,
              cost: LOGGED_IN_REPAIR_COST,
            });
          }
        })
        .catch((error) => {
          Logger.error('Error retrieving account details for setting initial values', {
            error: JSON.stringify(error),
            accountId: user?.accountId,
          });
          setInitialValues({ ...emptyAccount, ...evCharger, ...epdEligibility });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    user?.accountId,
    journey,
    selectedProduct,
    loading,
    isCreditEligible,
    setRepair,
    evCharger,
    repair,
    setLoading,
    kapiAccountDetailsEnabled,
    epdEligibility,
  ]);

  useEffect(() => {
    mixpanelRef.current.track(MixpanelEvents.PAGE_PERSONAL_DETAILS, {
      pageName: null,
    });

    optimizelyRef.current?.track(MixpanelEvents.PAGE_PERSONAL_DETAILS);
  }, []);

  return (
    <NewPersonalDetails
      location={location}
      initialValues={initialValues}
      isLoggedIn={!!user?.accountId}
      isHomeRecover={journey === Journey.HomeRecover}
      onSubmit={(values: PersonalDetailsInputsType) => {
        const {
          addressLine1,
          email,
          evChargerOutput,
          evChargerUseGenerateIncome,
          evChargerOwnership,
          forename,
          postcode,
          surname,
          telephone,
          title,
          town,
          addressLine2,
          homeOwnership,
          notGuesthouse,
        } = values;
        const outcodeLayer = getOutcode(values.postcode);
        DataLayer.push([
          {
            postcodeOutcode: outcodeLayer,
          },
        ]);
        setPersonalDetails({
          addressLine1,
          email,
          forename,
          postcode,
          surname,
          telephone,
          title,
          town,
          addressLine2,
        });
        setEVCharger({ evChargerOutput, evChargerUseGenerateIncome, evChargerOwnership });

        if (homeOwnership !== undefined && notGuesthouse !== undefined) {
          setEPDEligibility({ homeOwnership, notGuesthouse });
        }

        AccountService.save(values);

        if (evChargerSelected) {
          navigate(ROUTE_EV_PAYMENT);
          return;
        }

        if (epdCoverSelected) {
          navigate(ROUTE_EPD_PAYMENT);
          return;
        }

        if (journey === Journey.HomeRecover) {
          navigate(ROUTE_HOME_RECOVER_BOILER);
          return;
        }

        navigate(ROUTE_BOILER);
      }}
      onClickBack={() => {
        const referrerUrl = getReferrerUrlFor(tracking.source);
        if (referrerUrl) {
          window.location.href = referrerUrl;
          return;
        }
        if (journey === Journey.HomeRecover) {
          navigate(ROUTE_HOME_RECOVER_REPAIR_DETAILS);
          return;
        }
        if (journey === Journey.InsuranceHighExcess) {
          navigate('/95-excess');
          return;
        }
        if (isAgent) {
          navigate('/abs-agent?promoCode=OCEAN1');
          return;
        }
        if (journey === Journey.Abs) {
          navigate('/abs');
          return;
        }
        if (evChargerSelected) {
          navigate(ROUTE_EV_LANDING);
          return;
        }
        if (epdCoverSelected) {
          navigate(ROUTE_EPD_LANDING);
          return;
        }
        navigate('/');
      }}
    />
  );
};

export const NewPersonalDetailsPage = withOptimizely<Props, null>(NewPersonalDetailsPageComponent);
