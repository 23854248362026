import { useQuery } from 'react-query';

import * as Logger from '@src/logging/logger';
import { getQuotes } from '@src/api-requests/getQuotes/getQuotes';
import { GetQuotesOptions } from '@src/api-requests/getQuotes/types';

export type QuotesQueryOptions = GetQuotesOptions;

export const useQuotesQuery = (options: QuotesQueryOptions) =>
  useQuery(['getQuotes', options], () => getQuotes(options), {
    cacheTime: 10 * 60 * 1000, // 10 minutes
    staleTime: 10 * 60 * 1000, // 10 minutes
    retry: 1,
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onError: (error) => {
      Logger.error('Error fetching quotes', {
        error: error instanceof Error ? error.message : JSON.stringify(error),
      });
    },
  });
