import { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useQuotesQuery } from './queries/useQuotesQuery';

import { BoilerBrand } from '@entities/enums';
import { filterQuotesWrappersByExcess } from '@utils/filterQuotesWrappersByExcess';
import { homePlanStoreAtom } from '@src/store/store';
import { ProductCode } from '@src/types/Quote';
import { Journey } from '@src/types/Journey';

interface IUseQuotesPlatform {
  excess?: number;
}

export const useQuotesPlatform = ({ excess }: IUseQuotesPlatform = {}) => {
  const { promoCode, journey, isAgent, isCreditEligible, boilerDetails } =
    useAtomValue(homePlanStoreAtom);

  const { data, isLoading, error } = useQuotesQuery({
    isAbs: journey === Journey.Abs,
    isAgent,
    applianceId: boilerDetails.applianceId,
    boilerAge: boilerDetails.boilerAge,
    boilerMake: boilerDetails.make === '' ? BoilerBrand.DEFAULT : boilerDetails.make,
    is95Excess: journey === Journey.InsuranceHighExcess,
    isCreditEligible,
    promoCode,
    isHomeRecover: journey === Journey.HomeRecover,
  });

  const quotesWrappers = useMemo(() => {
    let value = data?.result || [];

    if (excess !== undefined) {
      value = filterQuotesWrappersByExcess(value, excess);
    }

    return value;
  }, [data?.result, excess]);

  const allQuotes = useMemo(() => {
    return quotesWrappers.flatMap((quotesWrapper) => quotesWrapper.quotes);
  }, [quotesWrappers]);

  const getQuoteWrapperByProductCode = useCallback(
    (productCode: ProductCode) => {
      return quotesWrappers.find(({ product }) => product.code === productCode);
    },
    [quotesWrappers]
  );

  const getQuoteByProductCode = useCallback(
    (productCode: ProductCode) => {
      return allQuotes.find((quote) => quote.product === productCode);
    },
    [allQuotes]
  );

  const getQuoteByProductCodeAndExcess = useCallback(
    (productCode: ProductCode, excess: number) => {
      const quotes = filterQuotesWrappersByExcess(data?.result, excess);
      return quotes
        .flatMap((quotesWrapper) => quotesWrapper.quotes)
        .find((quote) => quote.product === productCode);
    },
    [data?.result]
  );

  return {
    quotesWrappers,
    allQuotes,
    getQuoteWrapperByProductCode,
    getQuoteByProductCode,
    getQuoteByProductCodeAndExcess,
    isNotEligible: !!data?.noteligible.length,
    isLoading,
    error,
  };
};
