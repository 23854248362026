import { WindowLocation } from '@reach/router';

import { PersonalDetailsInputsType } from './PersonalDetailsInputs';
import { NewPersonalDetailsForm } from './NewPersonalDetailsForm';

import { NewBasicPage } from '@components';
import { TopSummary } from '@components/TopSummary/TopSummary';
import { getStepTitle } from '@utils/getStepTitle';

interface PersonalDetailsProps {
  initialValues: PersonalDetailsInputsType | undefined;
  location: WindowLocation;
  isLoggedIn: boolean;
  isHomeRecover: boolean;
  onClickBack(): void;
  onSubmit(values: PersonalDetailsInputsType): void;
}

export const NewPersonalDetails = ({
  location,
  initialValues,
  isLoggedIn,
  isHomeRecover,
  onClickBack,
  onSubmit,
}: PersonalDetailsProps) => {
  const title = 'Personal Details';
  const aboveHeading = getStepTitle(location);

  return (
    <NewBasicPage pageName="personal-details" location={location} warnOnLeaving>
      <TopSummary {...{ title, aboveHeading }} />
      {initialValues && (
        <NewPersonalDetailsForm
          initialValues={initialValues}
          isLoggedIn={isLoggedIn}
          isHomeRecover={isHomeRecover}
          onClickBack={onClickBack}
          onSubmit={onSubmit}
        />
      )}
    </NewBasicPage>
  );
};
