import { GetQuotesOptions, GetQuotesRequestBody, GetQuotesResponse } from './types';
import { createGetQuotesRequestBody } from './createGetQuotesRequestBody';
import { createGetQuotesRequestHeaders } from './createGetQuotesRequestHeaders';

import { OVO_QUOTES_SERVICE_URL } from '@constants/endpoints';
import * as Logger from '@src/logging/logger';
import HttpService from '@services/HttpService';

export const getQuotes = async (options: GetQuotesOptions): Promise<GetQuotesResponse> => {
  const body = createGetQuotesRequestBody(options);
  const headers = createGetQuotesRequestHeaders(options);

  Logger.debug('Requesting new quotes');

  const response = await HttpService.post<GetQuotesResponse, GetQuotesRequestBody>({
    endpoint: OVO_QUOTES_SERVICE_URL,
    useCredentials: false,
    headers,
    body,
  });

  Logger.debug('Received new quotes');

  return response;
};
