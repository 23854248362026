import { IQuotesWrapper } from '@src/types/Quote';

export const filterQuotesWrappersByExcess = (
  quotesWrappers: IQuotesWrapper[] | undefined,
  excess: number | undefined
) => {
  if (!quotesWrappers) {
    return [];
  }

  if (excess === undefined) {
    return quotesWrappers;
  }

  const filteredQuotesWrappers = quotesWrappers.map(({ quotes, ...restOfQuotesWrapper }) => ({
    quotes: quotes.filter((quote) => quote.excess === excess),
    ...restOfQuotesWrapper,
  }));

  return filteredQuotesWrappers;
};
