import React from 'react';
import styled from 'styled-components';
import { TextLink } from '@ovotech/element';

import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { IQuotesWrapper, DocumentType } from '@src/types/Quote';
interface Props {
  quoteWrapper: IQuotesWrapper;
}

const StyledAnchor = styled(TextLink)`
  text-align: center;
  cursor: pointer;
  padding-bottom: 0;
`;

export const NewProductInfoLink: React.FC<Props> = ({ quoteWrapper }) => {
  const { track } = useCustomMixpanel();

  const { documents, product } = quoteWrapper;

  const ipid = documents.find((doc) => doc.type === DocumentType.IPID);

  return (
    <StyledAnchor
      href={ipid?.url}
      target="_blank"
      rel="noopener noreferrer"
      data-testid={`product-information-${product.name}`}
      aria-label={`${product.name} Plan Product Information`}
      onClick={() =>
        track(MixpanelEvents.BUTTON_CLICK, {
          buttonId: 'IPID',
          coverType: product.name,
        })
      }
    >
      Product Information (IPID)
    </StyledAnchor>
  );
};
